import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanIDistributeAmazonGift = ({ location }) => {
  const title =
    "Can I set up an Amazon gift certificate as a giveaway with Instant Win?"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoT campaigns can distribute Amazon Gifts as gifts. Other unique coupons and QUO Card Pay can also be distributed."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Can I distribute Amazon gift certificates as campaign prizes?
            </h1>
            <p className="qa-page__answer">Yes, it is possible.</p>
            <p className="qa-page__text">
              Using PARKLoT's functionality, it is possible to automate the
              distribution of Amazon gift certificates. The system can also
              automate the process of drawing winners, eliminating any hassle.
            </p>

            <div className="qa-page__detail-container">
              {/* <h2 className="qa-page__h2">  
              【法人向け】 Amazonギフト券をキャンペーンの景品として利用できない商品・サービスは下記です。
            </h2> */}
              <div>
                <h2 className="qa-page__h2">
                  [Corporate] Products and services for which Amazon gift
                  certificates cannot be used as campaign prizes include
                </h2>
                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>tobacco</li>
                    <li>Pornographic and adult products and services</li>
                    <li>law-evading drug (e.g. amyl nitrates, MiPT)</li>
                    <li>Dating services*1</li>
                    <li>Gambling*2</li>
                    <li>online auction</li>
                    <li>Consumer finance*3</li>
                    <li>
                      Daily deal and discount service businesses (e.g., joint
                      purchase coupon services)
                    </li>
                    <li>Live Streaming Service</li>
                    <li>virtual currency</li>
                    <li>telemarketing</li>
                    <li>
                      Trading in embargoed goods or with embargoed countries
                    </li>
                    <li>firearms</li>
                    <li>Religious and political activities</li>
                    <li>
                      Products and services that Amazon deems illegal or
                      inappropriate
                    </li>
                  </ol>
                </div>
                <h3 className="qa-page__h3">
                  For more information, please refer to the Guidelines for the
                  Use of Corporate Amazon Gift Certificates.
                </h3>
                <p className="qa-page__text">
                  For more information, please visit Amazon's website.
                </p>
                <p className="qa-page__text">
                  [Related Link]
                  <a
                    href="https://www.amazon.co.jp/b?ie=UTF8&node=4731316051"
                    class="information__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Products and services for which Amazon gift certificates
                    cannot be used (as of June 2019)
                  </a>
                </p>
              </div>
              <div>
                <h2 className="qa-page__h2">
                  Distribution of Other Gift Certificates
                </h2>
                <p className="qa-page__text">
                  Automated distribution of Amazon gift certificates in
                  combination with PARKLoT's services is available as an option.
                </p>
                <p className="qa-page__text">
                  Various gift codes, coupons and vouchers other than Amazon
                  gift certificates can also be distributed directly to users
                  via Twitter direct messages.
                </p>
                <p className="qa-page__text">
                  PARKLoT allows you to automate the tedious task of
                  distributing Amazon gift certificates.
                </p>
                <p className="qa-page__text">
                  Please contact us for more information.
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanIDistributeAmazonGift
